
import { Options, Vue } from 'vue-class-component';
import { BaseAuthClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import InfoModal from '@/components/modals/infoModal.vue';
import store from '@/store';
import * as OM from "@/Model";

@Options({})
export default class Login extends Vue {

    languageCredential: OM.LanguageCredential = new OM.LanguageCredential();

    created() {
        var loggedUser = store.state.loggedUser;
        this.languageCredential.preferredCulture = loggedUser.preferredCulture;
    }

    login() {
        BaseAuthClient.login(this.languageCredential)
        .then(x => {
            LoginServices.LoginCallback(x.token, x.refreshToken);
        })
        .catch(y => {
            this.$opModal.show(InfoModal, {
                text:this.$localizationService.getLocalizedValue("Error", "Error"),
                subtitle: y.Message,
                deny: this.$localizationService.getLocalizedValue("Close", "Close"),
                denyCb: () => {
                    this.$opModal.closeLast();
                }
            })
        })
    }

    get disabled() {
        return !this.languageCredential.username || !this.languageCredential.password;
    }

}
